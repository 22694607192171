import React from "react"
import Layout from "../components/layouts"

export default () => (
  <Layout>
    <div className={`hero compact`}>
      <div className="hero__inner container">
        <h1 className={`[ title -no-margin -black"]`}>
          <h1>Oops...</h1>
        </h1>
      </div>
    </div>
    <div className="standard-content">
      <div className="[ container -pinched-large ]">
        <div className="standard-content__content">
          <div
            className="homepage-slice-wrapper"
            style={{ textAlign: "center" }}
          >
            <h1>This page could not be found</h1>
            <p>
              <a href="/">Return to homepage</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
